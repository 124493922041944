.settings-modal {
    position: relative;
    border-radius: 25px;
    overflow: hidden;
    width: 30vw;             
    height: 450px;
    margin: 25vh auto;
    display: flex;          
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1;
  }
  
  .settings-modal::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 25px;
    border: 2px solid transparent;
    -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
    pointer-events: none;
    z-index: 1;
  }
  .settings-modal {
    background-color: rgb(193, 173, 144);
    border: 3px solid rgb(102, 87, 67);
  }
  .settings {
    flex: 1;
    width: 100%;            
    padding: 0px 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 10;
    align-items: center;
    position: relative;
    z-index: 2;
    color: white;
    box-sizing: border-box;
  }
  .settings h2 h3 {
    margin: 0;
  }

.settings-section{
    display: flex;
    flex-direction: column;
    width: 80%;
    text-align: center;
}

.open-settings-button{
    position: absolute;
    right: 10px;
    top: 85px;
    height: 60px;
    width: 60px;
    background-color: rgb(193, 173, 144);
    color: black ;
    border: rgb(102, 87, 67) 1px solid;
    border-radius: 50%;
    margin: 10px;
    display: flex; 
    justify-content: center; 
    align-items: center;
    cursor: pointer;
    position: absolute;
}

.slider {
    -webkit-appearance: none;
    width: 100%;
    transform-origin: center;
    height: 6px;
    background: transparent;
    margin: 10px 0;
    cursor: pointer;
  }
  
  .slider::-webkit-slider-runnable-track {
    height: 6px;
    background: rgb(102, 87, 67);
    border-radius: 3px;
  }
  .slider::-moz-range-track {
    height: 6px;
    background: rgb(102, 87, 67);
    border-radius: 3px;
  }
  
  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 14px;
    height: 14px;
    background: white;
    border-radius: 50%;
    border: 2px solid rgba(255, 255, 255, 0);
    margin-top: -4px;
    transition: background 0.3s ease;
  }
  .slider::-moz-range-thumb {
    width: 14px;
    height: 14px;
    background: white;
    border-radius: 50%;
    border: 2px solid white;
    transition: background 0.3s ease;
  }
  
  .slider:hover::-webkit-slider-thumb,
  .slider:focus::-webkit-slider-thumb {
    background: white;
  }
  .slider:hover::-moz-range-thumb,
  .slider:focus::-moz-range-thumb {
    background: white;
  }

.settings-button{
    padding: 10px;
    font-family: 'BackToSchool';
    text-shadow: 
    0px 0px 0 black,  
    3px 0px 0 black,
    0px  3px 0 black,
    3px  3px 0 black;
    line-height: 1;
    border-radius: 30px;
    border-width: 2px solid transparent;
    padding: 10px 15px;
    min-height: 40px;
    transition: .2s;
    text-transform: capitalize;
    color: #fff;
    background-color: #2f90db;
    box-shadow: 0px 4px 0px 0px #2673AF;
    border: 1px solid #2f90db;
    border-bottom: 2px solid #ACD3F1;
    border-radius: 5px;
    cursor: pointer;
}
  